// import React from "react"
// import PropTypes from "prop-types"
// import styled from "styled-components"
// import { Image } from 'react-bootstrap'

// const Container = styled.div`
//   background: url(${({ img }) => img}) center no-repeat;
//   background-size: 100% 100%;
//   width: 100%;
//   /* height:705px; */
//   max-height: 32rem;

//   img {
//     width: 100%;
//   }
// `

// // const Banner = ({ img }) => {
// //   return <Container img={img} />
// // }

// const Banner = ({ img }) => {
//   return <Container><Image src={img}></Image></Container>
// }

// Banner.propTypes = {
//   img: PropTypes.string,
// }

// Banner.defaultProps = {
//   img: ``,
// }

// export default Banner

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Container = styled.div`
  background: url(${({ img }) => img}) center no-repeat;
  background-size: 100% 100%;
  width: 100vw;
  height: 32rem;
`

const Banner = ({ img }) => {
  return <Container img={img} />
}

Banner.propTypes = {
  img: PropTypes.string,
}

Banner.defaultProps = {
  img: ``,
}

export default Banner
