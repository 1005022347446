import styled from "styled-components"

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${props => (props.width ? props.width : "100%")};
  padding-top: 38px;
  margin-bottom: 1rem;
`

export default Wrapper
