import React from "react"
import styled from "styled-components"
import SEO from "../../components/seo"
import Layout from "../../layouts/layout"
import Banner from "../../components/banner"
import Wrapper from "../../components/wrapper"
import { Image } from 'react-bootstrap'

const data = [
  "云南城建梦居家政服务有限公司成立于2017年10月，是云南城建物业集团有限公司的全资控股公司，主要业务包括家政服务及家政职业资格培训。公司始终秉承为客户配置优质居家服务人员的使命，致力打造一支高素质、优质量、全方位的家政护理服务团队。为了保障服务品质和用户体验感，每一位服务师都经过系统培训及严格考核，确保让每一位客户享受标准化、精细化的服务。",
  "项目主要包括：保姆、育儿嫂、月嫂派遣、新房开荒保洁、住家深度保洁、清洁钟点工、会务等综合服务，致力给您一个舒适的家。",
  "梦居家政培训地点两处：1、云南映象70栋301—801室，2、梁源小区城建教育基地，培训学习科目有：母婴护理师、母婴妈妈班、催乳师、小儿推拿、育婴师、早教师等从事家政行业学习项目。拥有强大的师资团队，学习环境优良、舒适；宿舍环境干净、整洁，让学习期间有家的温度。",
]

const WrapperPlue = styled(Wrapper)`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > img {
    margin-bottom: 50px;
    width: 100%;
    height: 100%;
  }
`

const Container = styled.div`
  margin-bottom: 5.7rem;

  .desc-box {
    background: url("http://maps.yncce.cn/website/jzfwf00.png") center no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 520px;
    margin: 50px 0;
    position: relative;

    .box {
      max-width: 50%;
      height: 100%;
      padding: 40px 0;
      position: absolute;
      left: 50%;

      .desc {
        height: 100%;
        width: 45rem;

        padding: 30px 20px;
        background-color: rgba(255, 255, 255, 0.2);
        overflow-y: scroll;

        &:hover {
          ::-webkit-scrollbar-thumb {
            background-color: rgba(255, 255, 255, 0.7);
          }
        }

        &::-webkit-scrollbar {
          width: 16px;
          background-color: rgba(255, 255, 255, 0.4);
          padding: 10px 0;
        }

        &::-webkit-scrollbar-thumb {
          background-color: transparent;
          transition: background-color 2s;
        }

        p {
          text-indent: 2em;
          letter-spacing: 2px;
          font-size: 20px;
          color: white;
        }
      }
    }
  }
`

const Page = () => {
  return (
    <Layout>
      <SEO title="家政服务" />
      <Banner img="http://maps.yncce.cn/website/jzfw1.png" />
      <Container>
        <div className="desc-box">
          <div className="box">
            <div className="desc">
              {data.map((d, i) => (
                <p key={i}>{d}</p>
              ))}
            </div>
          </div>
        </div>
        <WrapperPlue>
          <Image src="http://maps.yncce.cn/website/jzfwf01.png" fluid />
          <Image src="http://maps.yncce.cn/website/jzfwf02.png" fluid />
        </WrapperPlue>
      </Container>
    </Layout>
  )
}

export default Page
